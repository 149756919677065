/*############### IMPORT CSS ###############*/
import "../styles/main.css";

/*############### MENU SETUP ###############*/
const navMenu = document.getElementById('nav-menu'),
    navToggle = document.getElementById('nav-toggle'),
    navClose = document.getElementById('nav-close')

if (navToggle) {
    navToggle.addEventListener('click', () => {
        navMenu.classList.add('show-menu')
    })
}

if (navClose) {
    navClose.addEventListener('click', () => {
        navMenu.classList.remove('show-menu')
    })
}

const navLink = document.querySelectorAll('.nav__link')

function linkAction() {
    const navMenu = document.getElementById('nav-menu')

    navMenu.classList.remove('show-menu')
}
navLink.forEach(n => n.addEventListener('click', linkAction))

/*############### CHANGE BG HEADER ###############*/
function scrollHeader() {
    const header = document.getElementById('header')
    // When the scroll is greater than 50 viewport height, add the scroll-header class to the header tag
    if (this.scrollY >= 50) header.classList.add('scroll-header'); else header.classList.remove('scroll-header')
}
window.addEventListener('scroll', scrollHeader)

/*############### SWIPER SECTION 2 ###############*/
const swiperSection2 = new Swiper('.section2__content-swiper', {
    // Optional parameters
    loop: true,
    spaceBetween: 24,
    slidesPerView: "auto",
    centeredSlides: true,

    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
});

/*############### SCROLL ACTIVE LINK ###############*/


/*############### SHOW SCROLL UP ###############*/
function scrollUp() {
    const scrollUp = document.getElementById('scroll-up');
    // When the scroll is higher than 460 viewport height, add the show-scroll class to the a tag with the scroll-top class
    if (this.scrollY >= 460) scrollUp.classList.add('show-scroll'); else scrollUp.classList.remove('show-scroll')
}
window.addEventListener('scroll', scrollUp)


/*############### CUSTOM CURSOR ###############*/
var cursor = document.querySelector("#cursor");
var frames = document.querySelectorAll(".frame");
var scroll = document.querySelector(".scrollup");

/*############### HEADER SLIPSE ###############*/
// frames.forEach(frame => {
//     frame.addEventListener("mousemove", function (dets) {
//         gsap.to(cursor, {
//             css:{zIndex:30}
//         })

//         gsap.to(cursor, {
//             scale: 8,
//         })

//         gsap.to(frame.children, {
//             css:{zIndex: 40}
//         })

//         gsap.to(frame.children, {
//             color: "#fff",
//             duration: .4,
//         })
//     })

//     frame.addEventListener("mouseleave", function (dets) {
//         gsap.to(cursor, {
//             scale: 1
//         })

//         gsap.to(frame.children, {
//             color: "var(--text-color)",
//             duration: .4,
//             y: 0
//         })
//     })
// })

/*############### MOUSE MOVE ###############*/
window.addEventListener("mousemove", function (dets) {
    // console.log(dets.clientX, dets.clientY);
    // cursor.style.transform = `translate(${dets.clientX}px, ${dets.clientY}px)`;
    gsap.to(cursor, {
        x: dets.clientX,
        y: dets.clientY,
        duration: .3,
        ease: Expo
    })
})

/*############### MAGNETIC LINK ###############*/
let items = document.querySelectorAll(".magnetic").forEach(item => {
    item.addEventListener("mousemove", (e) => {
        let x = e.offsetX;
        let y = e.offsetY;

        let itemWidth = item.clientWidth;
        let itemHeight = item.clientHeight;

        let transX = (x - itemWidth / 2);
        let transY = (y - itemHeight / 2);

        item.style.transform = `translateX(${transX}px)
        translateY(${transY}px)`;

        gsap.to(cursor, {
            scale: 8
        })

        gsap.to(scroll, {
            backgroundColor: "#fff",
        })

        gsap.to(scroll.children, {
            color: "var(--title-color)",
        })

    })

    item.addEventListener("mouseout", (e) => {
        item.style.transform = ``;

        gsap.to(cursor, {
            scale: 1
        })

        gsap.to(scroll, {
            backgroundColor: "var(--first-color)",
        })

        gsap.to(scroll.children, {
            color: "#fff",
        })
    })
})

/*############### LOCOMOTIVE SCROLL ###############*/
// function loco() {
//     const locoScroll = new LocomotiveScroll({
//         el: document.querySelector(".main"),
//         smooth: true,
//     });
// }

// loco();

/*############### EXPLORE TABS ###############*/

    const tabs = document.querySelectorAll(".explore-tab");
    const allContent = document.querySelectorAll(".explore__content");

    tabs.forEach((tab, index) => {
        tab.addEventListener("click", () => {
            tabs.forEach(tab => { tab.classList.remove("active-tab") });
            tab.classList.add("active-tab");

            allContent.forEach(content => { content.classList.remove("active") })
            allContent[index].classList.add("active")
        })
    })



    const tabs2 = document.querySelectorAll("#explore-tab");
    const content = document.querySelectorAll("#explore__content");

    tabs2.forEach((tab, index) => {
        tab.addEventListener("click", () => {
            tabs2.forEach(tab => { tab.classList.remove("active-tab") });
            tab.classList.add("active-tab");

            content.forEach(content => { content.classList.remove("active") })
            content[index].classList.add("active")
        })
    })


/*############### OVERVIEW TABS ###############*/
const overviewTabs = document.querySelectorAll(".section1__tab");
const overviewAllContent = document.querySelectorAll(".overview__section1-image");

overviewTabs.forEach((tab, index) => {
    tab.addEventListener("click", () => {
        overviewTabs.forEach(tab => { tab.classList.remove("overview-active-tab") });
        tab.classList.add("overview-active-tab");
        
        overviewAllContent.forEach(content => { content.classList.remove("active") })
        overviewAllContent[index].classList.add("active")
    })
})

/*############### SCROLL REVEAL ###############*/
const sr = ScrollReveal({
    origin: 'top',
    distance: '60px',
    duration: 2500,
    delay: 400,
    // reset: true
})

sr.reveal(`.hero__data, .works__data, .overview__section1-data, .overview__section2-data, .section4__data`)
sr.reveal(`.explore__content,`)
sr.reveal(`.works__content, .section4__content`,{origin: 'left'})
sr.reveal(`.overview__section1-content`,{origin: 'right'})
sr.reveal(`.hero__logos, .hero__image, .overview__section3, .explore__data, .footer__content`,{origin: 'bottom'})